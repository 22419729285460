/* .Toastify__toast-container {
  max-width: 420px;
  width: unset;
}

.Toastify__toast {
  padding: 0;
  background: none !important;
  border-radius: 12px;
}

.Toastify__close-button {
  display: none;
} */

/** Classes for the displayed toast **/
.Toastify__toast {
  border-radius: 12px;
  color: white;

}
.Toastify__toast:before {
  content: "";
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  background: white;
  margin-right: 12px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: center;
}

.Toastify__toast--success {
  background: #2F964F;
}
.Toastify__toast--success::before {
   background-image: url("./assets/images/files/alert-success.svg");
}
.Toastify__toast--warning {
  background: #EC952F;
}
.Toastify__toast--warning::before {
   background-image: url("./assets/images/files/alert-warning.svg");
}
.Toastify__toast--error {
  background: #ED2A36;
}
.Toastify__toast--error::before { 
  background-image: url("./assets/images/files/alert-close.svg");
}
